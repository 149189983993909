import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
     
	 <path fill="none" d="M0 0h48v48h-48z"/>
	 <path d="M34.42 18l-8.76-13.11c-.38-.58-1.02-.85-1.66-.85-.64 0-1.28.28-1.66.85l-8.76 13.11h-9.58c-1.1 0-2 .9-2 2 0 .19.03.37.07.54l5.07 18.54c.47 1.68 2.02 2.92 3.86 2.92h26c1.84 0 3.39-1.24 3.85-2.93l5.07-18.54c.05-.16.08-.34.08-.53 0-1.1-.9-2-2-2h-9.58zm-16.42 0l6-8.8 6 8.8h-12zm6 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>


    </Svg>
  );
};

export default Icon;


{/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path fill="none" d="M0 0h48v48h-48z"/><path d="M34.42 18l-8.76-13.11c-.38-.58-1.02-.85-1.66-.85-.64 0-1.28.28-1.66.85l-8.76 13.11h-9.58c-1.1 0-2 .9-2 2 0 .19.03.37.07.54l5.07 18.54c.47 1.68 2.02 2.92 3.86 2.92h26c1.84 0 3.39-1.24 3.85-2.93l5.07-18.54c.05-.16.08-.34.08-.53 0-1.1-.9-2-2-2h-9.58zm-16.42 0l6-8.8 6 8.8h-12zm6 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/></svg> */}
