import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
    <path d="M28,2H4C2.346,2,1,3.346,1,5v20c0,1.654,1.346,3,3,3h1v2c0,0.552,0.447,1,1,1h5c0.553,0,1-0.448,1-1v-2h8v2
		c0,0.552,0.447,1,1,1h5c0.553,0,1-0.448,1-1v-2h1c1.654,0,3-1.346,3-3V5C31,3.346,29.654,2,28,2z M10,29H7v-1h3V29z M25,29h-3v-1h3
		V29z M29,25c0,0.551-0.448,1-1,1H4c-0.552,0-1-0.449-1-1V5c0-0.551,0.448-1,1-1h24c0.552,0,1,0.449,1,1V25z"/><path d="M26,5H7C6.447,5,6,5.448,6,6v2H5C4.447,8,4,8.448,4,9v4c0,0.552,0.447,1,1,1h1v2H5c-0.553,0-1,0.448-1,1v4
		c0,0.552,0.447,1,1,1h1v2c0,0.552,0.447,1,1,1h19c0.553,0,1-0.448,1-1V6C27,5.448,26.553,5,26,5z M7,20H6v-2h1V20z M7,12H6v-2h1V12
		z M25,23H8v-1c0.553,0,1-0.448,1-1v-4c0-0.552-0.447-1-1-1v-2c0.553,0,1-0.448,1-1V9c0-0.552-0.447-1-1-1V7h17V23z"/><path d="M17,21c3.309,0,6-2.691,6-6s-2.691-6-6-6s-6,2.691-6,6S13.691,21,17,21z M17,11c2.206,0,4,1.794,4,4s-1.794,4-4,4
		s-4-1.794-4-4S14.794,11,17,11z"/><path d="M17,18c1.654,0,3-1.346,3-3s-1.346-3-3-3s-3,1.346-3,3S15.346,18,17,18z M17,14c0.552,0,1,0.449,1,1s-0.448,1-1,1
		s-1-0.449-1-1S16.448,14,17,14z"/>
    </Svg>
  );
};

export default Icon;
