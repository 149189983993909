import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 92 92" {...props}>
   <path d="M60.8 30.9c.8.8 1.3 2 1.3 3.2 0 1.2-.5 2.3-1.3 3.2-.8.8-2 1.3-3.2 1.3-1.2 0-2.4-.5-3.2-1.3-.8-.8-1.3-2-1.3-3.2s.5-2.3 1.3-3.2c.8-.8 2-1.3 3.2-1.3 1.2 0 2.4.5 3.2 1.3zm7 6.3c-1.2 0-2.3.5-3.2 1.3-.8.8-1.3 2-1.3 3.2 0 1.2.5 2.4 1.3 3.2.8.8 2 1.3 3.2 1.3 1.2 0 2.4-.5 3.2-1.3.8-.8 1.3-2 1.3-3.2 0-1.2-.5-2.3-1.3-3.2-.8-.8-2-1.3-3.2-1.3zm-34-2.2H31v-2.6c0-1.4-1.1-2.5-2.5-2.5S26 31 26 32.4V35h-2.6c-1.4 0-2.5 1.1-2.5 2.5S22 40 23.4 40H26v2.6c0 1.4 1.1 2.5 2.5 2.5S31 44 31 42.6V40h2.8c1.4 0 2.5-1.1 2.5-2.5S35.2 35 33.8 35zM92 66.1c0 4.3-2.4 7.8-6.2 9.3-3.8 1.4-9.8.8-15.2-5.3-2.6-3-7-8.4-9.4-11.4-12.8 3-25 1.1-30.3 0-2.4 3-6.8 8.4-9.4 11.4-3.9 4.4-8.1 6-11.6 6-1.4 0-2.6-.2-3.7-.6-3.8-1.4-6.2-5-6.2-9.3 0-11.8 7.6-30.3 10-35.8-.8-2.8-.5-5.7 1-8.3 1.9-3.3 5.3-5.8 8.3-6 2.6-.2 6.1 1.3 8.4 2.4 4.5-.8 18.7-2.9 36.6 0 2.2-1.1 5.8-2.6 8.4-2.4 2.9.2 6.4 2.7 8.3 6 1.5 2.6 1.9 5.5 1 8.3 2.4 5.4 10 23.9 10 35.7zm-8 0c0-11.9-9.9-33.9-10-34.1-.5-1.1-.5-2.4.1-3.5.4-.8.4-1.5.1-2.2-.5-1.2-1.7-2.1-2.2-2.3-.9.1-3.3 1.1-5.2 2.1-.8.4-1.7.6-2.6.4-20.5-3.6-36.2-.1-36.3 0-.9.2-1.9.1-2.8-.4-1.9-1-4.3-2-5.2-2.1-.5.3-1.7 1.1-2.2 2.3-.3.8-.3 1.4.1 2.2.5 1.1.6 2.4.1 3.5 0 .2-9.9 22.2-9.9 34.1 0 .9.4 1.5 1 1.8 1.4.5 3.9-.3 6.4-3.1 3.5-4 10.7-13 10.8-13.1 1-1.3 2.7-1.8 4.2-1.3.2 0 15.8 4.4 31.1 0 .4-.1.7-.2 1.1-.2 1.2 0 2.4.5 3.1 1.5.1.1 7.3 9.1 10.8 13.1 2.5 2.9 5 3.7 6.4 3.1.7-.3 1.1-.9 1.1-1.8z"/>
    </Svg>
  );
};

export default Icon;
