import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 512 512" {...props}>
     
<g>
	<g>
		<path d="M208.057,362.515c-15.603-10.082-36.424-5.606-46.506,9.996c10.082-15.603,5.606-36.424-9.996-46.506
			c-15.603-10.082-36.424-5.606-46.506,9.996c10.082-15.603,5.606-36.424-9.996-46.506c-15.603-10.082-36.424-5.606-46.506,9.996
			l-34.484,53.367c-10.082,15.603-5.606,36.424,9.996,46.506c15.603,10.082,36.424,5.606,46.506-9.996
			c-10.082,15.603-5.606,36.424,9.996,46.506c15.603,10.082,36.424,5.606,46.506-9.996c-10.082,15.603-5.606,36.424,9.996,46.506
			s36.424,5.606,46.506-9.996l34.484-53.367C228.135,393.418,223.659,372.596,208.057,362.515z"/>
	</g>
</g>
<g>
	<g>
		<path d="M436.601,270.49l-0.902-0.587L264.31,158.311l-27.309,41.448c-12.201,18.777-32.847,29.983-55.264,29.983
			c-12.686,0-25.037-3.647-35.716-10.548c-30.505-19.712-39.287-60.566-19.603-91.029l16.405-25.475L85.266,88.843L0,264.877
			l20.909,17.996l0.565-0.875c12.19-18.865,32.885-30.127,55.358-30.125c12.686,0.001,25.036,3.649,35.713,10.548
			c10.11,6.532,18.066,15.492,23.267,26.008c11.812,0.444,23.255,4.053,33.235,10.502c10.11,6.532,18.066,15.492,23.267,26.007
			c11.812,0.444,23.254,4.051,33.235,10.503c30.505,19.711,39.287,60.566,19.575,91.071l-18.284,28.296l28.307,18.767
			c15.603,10.082,36.424,5.606,46.506-9.996c10.082-15.603,5.606-36.424-9.996-46.506l11.442,7.394
			c15.603,10.082,36.424,5.606,46.506-9.996c10.082-15.603,5.606-36.423-9.995-46.505l11.441,7.393
			c15.603,10.082,36.424,5.606,46.506-9.996c10.081-15.602,5.608-36.42-9.992-46.503l11.438,7.391
			c15.603,10.082,36.424,5.606,46.506-9.996C455.354,301.022,451.316,280.821,436.601,270.49z"/>
	</g>
</g>
<g>
	<g>
		<path d="M467.068,33.035l-246.101,7.839l-67.449,104.74c-10.082,15.603-5.606,36.424,9.996,46.506
			c15.603,10.082,36.424,5.606,46.506-9.996l45.013-68.316l9.854,6.416l177.625,115.652L512,184.419L467.068,33.035z"/>
	</g>
</g>


    </Svg>
  );
};

export default Icon;
