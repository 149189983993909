export { default as FarmIcon } from "./Farm";
export { default as GroupsIcon } from "./Groups";
export { default as HamburgerIcon } from "./Hamburger";
export { default as HamburgerCloseIcon } from "./HamburgerClose";
export { default as HomeIcon } from "./Home";
export { default as IfoIcon } from "./Ifo";
export { default as InfoIcon } from "./Info";
export { default as LanguageIcon } from "./Language";
export { default as LogoIcon } from "./Logo";
export { default as MoonIcon } from "./Moon";
export { default as MoreIcon } from "./More";
export { default as NftIcon } from "./Nft";
export { default as PoolIcon } from "./Pool";
export { default as SunIcon } from "./Sun";
export { default as TelegramIcon } from "./Telegram";
export { default as TicketIcon } from "./Ticket";
export { default as TradeIcon } from "./Trade";
export { default as TwitterIcon } from "./Twitter";
export { default as AuditIcon } from "./Audit";
export { default as GooseIcon } from "./Goose";
export { default as HandshakeIcon } from "./Handshake";
export { default as ShopIcon } from "./Shop";
export { default as MoreboxIcon } from "./Morebox";
export { default as TigerarmyIcon } from "./Tigerarmy";
export { default as TigerpadIcon } from "./Tigerpad";
export { default as TigerdaoIcon } from "./Tigerdao";
export { default as TigerbankIcon } from "./Tigerbank";
export { default as TigervaultIcon } from "./Tigervault";
export { default as TigergamesIcon } from "./Tigergames";
export { default as TigerbetIcon } from "./Tigerbet";

