import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 67 67" {...props}>
     <path d="M33.5,30.7c7.7,0,14-6.2,14-14c0-7.7-6.3-14-14-14c-7.7,0-14,6.3-14,14C19.5,24.5,25.9,30.7,33.5,30.7z M33.5,17.7
		c-2.5,0-4.6-2.1-4.6-4.6c0-2.2,1.5-4,3.6-4.5V7c0-0.5,0.4-1,1-1c0.5,0,1,0.5,1,1v1.6c2,0.5,3.6,2.3,3.6,4.5c0,0.5-0.4,1-1,1
		c-0.5,0-1-0.5-1-1c0-1.4-1.1-2.5-2.5-2.6c0,0,0,0,0,0h-0.1c0,0,0,0,0,0c-1.4,0-2.5,1.2-2.5,2.6c0,1.4,1.2,2.6,2.6,2.6
		c2.5,0,4.6,2.1,4.6,4.6c0,2.2-1.6,4.1-3.8,4.5v1.6c0,0.6-0.5,1-1,1s-1-0.4-1-1v-1.7c-2-0.5-3.4-2.3-3.4-4.4c0-0.5,0.4-1,1-1
		c0.5,0,1,0.5,1,1c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S34.9,17.7,33.5,17.7z"/>
    <path d="M56.8,27.1L47.7,24c-0.1,0.1-0.4,0.8-1,1.7c0.2,0.1,0.1,0,6.7,2.3l-19.9,6.8l-19.9-6.8c6.8-2.4,6.6-2.2,6.7-2.3
		c-0.5-0.7-1-1.6-1.1-1.7l-9.1,3.1c-0.3,0.1-0.5,0.3-0.6,0.6L33.5,36l23.9-8.2C57.3,27.5,57.1,27.2,56.8,27.1z"/><polygon points="31.6 37.5 7.8 29.3 3.1 35.2 25.6 43.5"/>
    <path d="M25.5 45.6L8.5 39.3V56l24 8.3V39.5l-5.9 5.9C26.3 45.6 25.9 45.7 25.5 45.6zM40.4 45.4l-5.9-5.9v24.8L58.6 56V39.3l-17.1 6.3C41.1 45.7 40.7 45.6 40.4 45.4z"/>
    <polygon points="59.2 29.3 35.3 37.5 41.4 43.5 59.2 36.9 63.9 35.2"/>
    </Svg>
  );
};

export default Icon;
