import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 67 67" {...props}>
    <path d="M33.9,16.1c-0.7,0-1.2-0.6-1.2-1.2c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,1.4,0.9,2.6,2.1,3v0.8c0,0.6,0.4,1,1,1s1-0.4,1-1V18
		c1.3-0.4,2.3-1.6,2.3-3.1c0-1.8-1.4-3.2-3.2-3.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2c0,0,0,0,0,0s0,0,0,0
		c0.7,0,1.2,0.6,1.2,1.2c0,0.6,0.4,1,1,1s1-0.4,1-1c0-1.4-0.9-2.6-2.2-3.1V6.6c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.8
		c-1.3,0.4-2.2,1.6-2.2,3.1c0,1.8,1.4,3.2,3.2,3.2c0.7,0,1.2,0.6,1.2,1.2S34.6,16.1,33.9,16.1z"/>
    <path d="M65.9,16.6l-32-16c-0.3-0.1-0.6-0.1-0.9,0l-32,16c-0.3,0.2-0.6,0.5-0.6,0.9v6.1c0,0.6,0.4,1,1,1h0.9v3.1c0,0.6,0.4,1,1,1
		h0.3v3.1c0,0.5,0.4,0.9,0.8,1v17.8c-0.5,0.1-0.8,0.5-0.8,1v3.1H3.4c-0.6,0-1,0.4-1,1v3.1H1.5c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1
		h64c0.6,0,1-0.4,1-1v-6c0-0.6-0.4-1-1-1h-0.9v-3.1c0-0.6-0.4-1-1-1h-0.3v-3.1c0-0.5-0.4-0.9-0.8-1V32.6c0.5-0.1,0.8-0.5,0.8-1v-3.1
		h0.3c0.6,0,1-0.4,1-1v-3.1h0.9c0.6,0,1-0.4,1-1v-6.1C66.5,17.1,66.3,16.8,65.9,16.6z M2.5,18.1l31-15.5l31,15.5v4.5
		c-20.1,0-43.8,0-62,0V18.1z M60.5,50.4h-3.9V32.7h3.9V50.4z M53.5,28.6h0.3v3.1c0,0.5,0.4,0.9,0.8,1v17.8c-0.5,0.1-0.8,0.5-0.8,1
		v3.1h-0.3c-0.6,0-1,0.4-1,1v3.1h-4.6v-3.1c0-0.6-0.4-1-1-1h-0.3v-3.1c0-0.5-0.4-0.9-0.8-1V32.6c0.5-0.1,0.8-0.5,0.8-1v-3.1h0.3
		c0.6,0,1-0.4,1-1v-3.1h4.6v3.1C52.5,28.2,52.9,28.6,53.5,28.6z M31.2,58.5v-3.1c0-0.6-0.4-1-1-1h-0.3v-3.1c0-0.5-0.4-0.9-0.8-1
		V32.6c0.5-0.1,0.8-0.5,0.8-1v-3.1h0.3c0.6,0,1-0.4,1-1v-3.1h4.6v3.1c0,0.6,0.4,1,1,1h0.3v3.1c0,0.5,0.4,0.9,0.8,1v17.8
		c-0.5,0.1-0.8,0.5-0.8,1v3.1h-0.3c-0.6,0-1,0.4-1,1v3.1H31.2z M14.5,58.5v-3.1c0-0.6-0.4-1-1-1h-0.3v-3.1c0-0.5-0.4-0.9-0.8-1V32.6
		c0.5-0.1,0.8-0.5,0.8-1v-3.1h0.3c0.6,0,1-0.4,1-1v-3.1h4.6v3.1c0,0.6,0.4,1,1,1h0.3v3.1c0,0.5,0.4,0.9,0.8,1v17.8
		c-0.5,0.1-0.8,0.5-0.8,1v3.1h-0.3c-0.6,0-1,0.4-1,1v3.1H14.5z M6.5,32.7h3.9v17.7H6.5V32.7z M27.1,50.4h-3.9V32.7h3.9V50.4z
		 M22.4,52.4h5.6v2.1h-5.6V52.4z M27.9,30.7h-5.6v-2.1h5.6V30.7z M29.2,26.6c-0.2,0-8.5,0-8.1,0v-2.1h8.1V26.6z M21.1,56.4
		c2.1,0,6.2,0,8.1,0v2.1h-8.1V56.4z M43.8,50.4h-3.9V32.7h3.9V50.4z M39.1,52.4h5.6v2.1h-5.6V52.4z M44.6,30.7h-5.6v-2.1h5.6V30.7z
		 M45.9,26.6c-2.1,0-6.2,0-8.1,0v-2.1h8.1V26.6z M37.8,56.4c2.1,0,6.2,0,8.1,0v2.1h-8.1V56.4z M12.5,24.6v2.1c-2.1,0-6.2,0-8.1,0
		v-2.1H12.5z M11.2,28.6v2.1H5.7v-2.1H11.2z M5.7,52.4h5.6v2.1H5.7V52.4z M4.4,56.4c2.1,0,6.2,0,8.1,0v2.1H4.4V56.4z M64.5,64.5h-62
		v-4c14.6,0,47.4,0,62,0V64.5z M54.5,58.5v-2.1c0.2,0,8.5,0,8.1,0v2.1H54.5z M55.8,54.4v-2.1h5.6v2.1H55.8z M61.3,30.7h-5.6v-2.1
		h5.6V30.7z M62.6,26.6c-0.2,0-8.5,0-8.1,0v-2.1h8.1V26.6z"/>
    </Svg>
  );
};

export default Icon;
